import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { removeElementTags } from '@utils/removeElementTags'
import { isValidURL } from '@utils/helper'
import parse from 'html-react-parser'

import ElementImageFile from '@components/global/element/ImageFile'

import * as cx from './AboutUsCta.module.scss'

import SvgLogoAccessAlt from '@svg/logoAccessAlt.inline'

const AboutUsCta = ({
  background,
  title,
  titleClassName = 'heading-5',
  description,
  descriptionClassName = 'default-body large',
  items,
  itemHeadingClassName = 'subtitle-2',
  itemDescriptionClassName = 'default-body large',
  images,
  featuresLogo,
}) => {
  return (
    <section id="about-us-hero" className={clsx(cx.wrapper)}>
      <div className={clsx(cx.cta)}>
        <div className={clsx(cx.heading)}>
          <h2 className={clsx(titleClassName)}>{parse(title)}</h2>
          <p className={clsx(descriptionClassName)}>{parse(description)}</p>
        </div>

        <div className={clsx(cx.items)}>
          {items.map((item, index) => {
            const isItemAccess =
              removeElementTags(item.title) === 'Access' && cx.access

            return (
              <div
                key={index}
                className={clsx(
                  cx.item,
                  isItemAccess && cx.access,
                  'modal-shadow'
                )}
              >
                <div className={clsx(cx.subheading)}>
                  <h3 className={clsx(itemHeadingClassName)}>
                    {parse(item.title)}
                  </h3>
                  {isItemAccess && (
                    <div className={clsx(cx.svg)}>
                      <SvgLogoAccessAlt />
                    </div>
                  )}
                  <p className={clsx(itemDescriptionClassName)}>
                    {parse(item.description)}
                  </p>
                </div>
                <div className={clsx(cx.image)}>
                  <canvas width={412} height={340}></canvas>
                  <ElementImageFile
                    src={images[index]}
                    alt={removeElementTags(item.title)}
                    lazy={true}
                  />
                </div>
                {isValidURL(item.url) && (
                  <a
                    href={item.url}
                    className={clsx('primary-button', 'large', cx.link)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.label}
                  </a>
                )}
                {isValidURL(item.url) === false && (
                  <Link
                    to={item.url}
                    className={clsx('primary-button', 'large', cx.link)}
                  >
                    {item.label}
                  </Link>
                )}
              </div>
            )
          })}
        </div>
      </div>

      <div className={clsx(cx.featuresIn)}>
        <div className={clsx(cx.subheading)}>
          <h3 className="subtitle-1">As Featured in</h3>
        </div>
        <ul className={clsx(cx.logos)}>
          {featuresLogo.map((item, index) => {
            return (
              <li key={index} style={{ maxWidth: `${item.width + 24}px` }}>
                <ElementImageFile
                  src={item.image}
                  alt={item.title}
                  lazy={true}
                />
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

AboutUsCta.propTypes = {
  background: PropTypes.object,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  description: PropTypes.string,
  descriptionClassName: PropTypes.string,
  items: PropTypes.array,
  itemHeadingClassName: PropTypes.string,
  itemDescriptionClassName: PropTypes.string,
  images: PropTypes.array,
  featuresLogo: PropTypes.array,
}

export default AboutUsCta
