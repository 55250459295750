import React from 'react'
import clsx from 'clsx'

const SvgTrophy = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      style={{ enableBackground: 'new 0 0 48 48' }}
      className={clsx('fill-current', className)}
      fill="none"
    >
      <g clipPath="url(#clip0_805_15750)">
        <path
          d="M39.8559 0H14.2269C14.2193 0 14.2126 0.00190476 14.205 0.00190476C14.1974 0.00190476 14.1908 0 14.1832 0H8.14504C7.70418 0 7.34692 0.358095 7.34692 0.8V10.2571C7.34692 13.9276 10.2439 16.9257 13.8658 17.0924C15.0886 21.2429 18.7742 24.3343 23.2228 24.6695V31.0657C20.5738 31.3448 18.4616 32.9876 18.0246 35.081H14.6905C14.2497 35.081 13.8924 35.439 13.8924 35.881V40.74H10.908C10.4672 40.74 10.1099 41.0981 10.1099 41.54V47.2C10.1099 47.6419 10.4662 48 10.9071 48H37.1328C37.5736 48 37.9309 47.6419 37.9309 47.2V41.541C37.9309 41.099 37.5746 40.741 37.1337 40.741H34.1494V35.8819C34.1494 35.44 33.7921 35.0819 33.3512 35.0819H30.0172C29.5801 32.9886 27.468 31.3457 24.819 31.0667V24.6705C29.2694 24.3352 32.9579 21.2429 34.1807 17.0905C37.7808 16.9 40.654 13.9124 40.654 10.2571V0.8C40.653 0.358095 40.2967 0 39.8549 0H39.8559ZM21.6854 1.6V10.1L20.6878 9.42571C20.5529 9.33429 20.3971 9.28857 20.2412 9.28857C20.0854 9.28857 19.9296 9.33429 19.7947 9.42571L18.797 10.1V1.6H21.6845H21.6854ZM13.385 15.4486C10.8729 15.0629 8.94315 12.8819 8.94315 10.2581V1.6H13.385V15.4486ZM36.3356 46.399H11.7062V42.34H36.3356V46.399ZM32.5531 40.741H15.4887V36.6819H32.5531V40.741ZM28.3687 35.0819H19.674C20.1671 33.6695 21.9258 32.62 24.0218 32.62C26.1169 32.62 27.8756 33.6695 28.3687 35.0819ZM24.0218 23.1114C19.0621 23.1114 15.0259 19.0657 15.0259 14.0943V1.6H17.2046V11.6048C17.2046 11.901 17.3671 12.1724 17.6284 12.3105C17.8887 12.4486 18.2051 12.4324 18.4493 12.2676L20.2441 11.0543L22.0389 12.2676C22.1729 12.359 22.3287 12.4048 22.4855 12.4048C22.6137 12.4048 22.742 12.3733 22.8598 12.3114C23.1202 12.1724 23.2836 11.901 23.2836 11.6057V1.6H33.0215V14.1305C33.0016 19.0857 28.9721 23.1114 24.0218 23.1114ZM39.0578 10.2571C39.0578 12.881 37.128 15.0629 34.6159 15.4476V14.1286C34.6159 14.1162 34.6178 14.1048 34.6178 14.0924V1.6H39.0578V10.2571Z"
          fill="url(#paint0_linear_805_15750)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_805_15750"
          x1="4.60252"
          y1="48"
          x2="37.9099"
          y2="54.2968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8DF6F8" />
          <stop offset="1" stopColor="#6876D5" />
        </linearGradient>
        <clipPath id="clip0_805_15750">
          <rect
            width="33.3061"
            height="48"
            fill="white"
            transform="translate(7.34692)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgTrophy
