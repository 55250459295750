import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import parse from 'html-react-parser'
import { getImage } from 'gatsby-plugin-image'
import {
  useAboutUsMetadata,
  heroData,
  storyData,
  missionData,
  ourSolutionsData,
  ctaData,
  founderStatement,
} from '@hooks/about-us-metadata'
import clsx from 'clsx'
import useMedia, { media } from '@hooks/useMedia'

import Seo from '@components/seo/Index'
import LayoutInnerPage from '@src/layouts/InnerPage'
import AboutUsHero from '@components/pages/about-us/Hero'
import AboutUsStory from '@components/pages/about-us/Story'
import AboutUsMission from '@components/pages/about-us/Mission'
import AboutUsOurSolutions from '@components/pages/about-us/OurSolutions'
import AboutUsCta from '@components/pages/about-us/CallToAction'
import AboutUsPartner from '@components/pages/about-us/Partner'
import ReviewsServiceSetsUsApart from '@components/global/Reviews/ServiceSetsUsApart'

import * as cx from './AboutUsTemplate.module.scss'

const AboutUsTemplate = ({ data: { page }, pageContext: { seo, uri } }) => {
  const query = useAboutUsMetadata()
  const desktop = useMedia(media.desktop)
  const [isDesktop, setIsDesktop] = useState(false)

  const isDeviceMinTablet = useMedia(media.minTablet)
  const [isMinTablet, setIsMinTablet] = useState(false)

  useEffect(() => {
    setIsDesktop(desktop)
    setIsMinTablet(isDeviceMinTablet)
  }, [desktop, isDeviceMinTablet])

  return (
    <LayoutInnerPage hasWhiteTransparentNav>
      <Seo title={page.title} seo={seo} uri={uri} />
      <AboutUsHero
        breadcrumbs={page?.seo?.breadcrumbs}
        background={getImage(query.HeroBackground)}
        title={heroData.title}
        subtitle={heroData.subtitle}
        image={getImage(query.Hero)}
        description={heroData.description}
        descriptionClassName={clsx(
          isMinTablet && 'subtitle-5',
          !isMinTablet && 'default-body large'
        )}
      />
      <AboutUsStory
        background={getImage(query.StoryBackground)}
        image={getImage(query.StoryVidePlaceholder)}
        title={storyData.title}
        description={storyData.description}
        video={storyData.founded}
        features={storyData.features}
      />
      <AboutUsMission
        title={missionData.title}
        items={missionData.items}
        images={[
          getImage(query.Item1),
          getImage(query.Item2),
          getImage(query.Item3),
          getImage(query.Item4),
          getImage(query.Item5),
          getImage(query.Item6),
        ]}
      />
      <AboutUsOurSolutions
        image={getImage(query.OurSolutions)}
        title={ourSolutionsData.title}
        description={ourSolutionsData.description}
        items={ourSolutionsData.items}
      />
      <AboutUsCta
        title={ctaData.title}
        description={ctaData.description}
        items={ctaData.items}
        images={[
          getImage(query.RealEstateWebsite),
          getImage(query.RealEstateDigitalMarketing),
          getImage(query.RealEstateAccess),
        ]}
        featuresLogo={[
          {
            title: 'Real Trends',
            image: getImage(query.LogoRealTrends),
            width: 120,
          },
          {
            title: 'Inman',
            image: getImage(query.LogoInman),
            width: 89,
          },
          {
            title: 'Housing Wire',
            image: getImage(query.LogoHousingWire),
            width: 124,
          },
          {
            title: 'Tom Ferry',
            image: getImage(query.LogoTomFerry),
            width: 122,
          },
          {
            title: 'Luxury Portfolio',
            image: getImage(query.LogoLuxuryPortfolio),
            width: 110,
          },
          {
            title: 'Business Insider',
            image: getImage(query.LogoBusinessInsider),
            width: 100,
          },
          {
            title: 'Forbes',
            image: getImage(query.LogoForbes),
            width: 89,
          },
          {
            title: 'The Real Deal',
            image: getImage(query.LogoTheRealDeal),
            width: 129,
          },
        ]}
      />
      <ReviewsServiceSetsUsApart
        title="Five-Star Reputation"
        titleClassName={clsx(
          isDesktop && 'heading-5',
          !isDesktop && 'heading-3',
          cx.reviewHeading
        )}
        subtitle="We’re honored to call these A-list agents clients — and thrilled to receive their praise!"
        subtitleClassName={clsx('subtitle-5', cx.reviewSubtitle)}
      />
      <AboutUsPartner
        background={getImage(query.FounderBackground)}
        title={founderStatement.title}
        description={founderStatement.description}
        name={founderStatement.name}
        position={founderStatement.position}
        image={getImage(query.JonKrabbe)}
        signature={getImage(query.Signature)}
      />
    </LayoutInnerPage>
  )
}

export default AboutUsTemplate

export const AboutUsPageQuery = graphql`
  query AboutUsPageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      seo {
        breadcrumbs {
          text
          url
        }
      }
    }
  }
`
