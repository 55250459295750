import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { removeElementTags } from '@utils/removeElementTags'
import parse from 'html-react-parser'

import ElementImageFile from '@components/global/element/ImageFile'
import Breadcrumbs from '@components/seo/Breadcrumbs'

import * as cx from './AboutUsHero.module.scss'

const AboutUsHero = ({
  breadcrumbs,
  background,
  title,
  titleClassName = 'heading-5',
  subtitle,
  subtitleClassName = 'subtitle-7',
  description,
  descriptionClassName = 'subtitle-5',
  image,
  imageClassName,
}) => {
  const altTag = title !== undefined ? removeElementTags(title) : 'Hero Banner'

  return (
    <section id="about-us-hero" className={clsx(cx.wrapper)}>
      <ElementImageFile
        className={clsx(cx.background)}
        src={background}
        alt={`${altTag} Background`}
        lazy={false}
      />
      <Breadcrumbs
        wrapperClassName={clsx(cx.breadcrumbs, 'global-breadcrumbs')}
        data={breadcrumbs}
      />
      <div className={clsx(cx.heading)}>
        <p className={clsx(subtitleClassName)}>{parse(subtitle)}</p>
        <h1 className={clsx(titleClassName)}>{parse(title)}</h1>
      </div>
      <div className={clsx(cx.image, imageClassName)}>
        <ElementImageFile src={image} alt={altTag} lazy={false} />
      </div>
      <div className={clsx(cx.description)}>
        <p className={clsx(descriptionClassName)}>{description}</p>
      </div>
    </section>
  )
}

AboutUsHero.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  background: PropTypes.object,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleClassName: PropTypes.string,
  description: PropTypes.string,
  descriptionClassName: PropTypes.string,
  image: PropTypes.object,
  imageClassName: PropTypes.string,
}

export default AboutUsHero
