import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { removeElementTags } from '@utils/removeElementTags'
import useMedia, { media } from '@hooks/useMedia'
import parse from 'html-react-parser'

import ElementImageFile from '@components/global/element/ImageFile'
import ModalVideo from '@components/global/modal/Video'

import * as cx from './AboutUsStory.module.scss'

import SvgPlayAlt from '@svg/playAlt.inline'

const AboutUsStory = ({
  background,
  title,
  titleClassName = 'heading-5',
  description,
  descriptionClassName = 'subtitle-5',
  image,
  imageClassName,
  video,
  features,
}) => {
  const modalVideoFunc = useRef()
  const altTag = title !== undefined ? removeElementTags(title) : 'Hero Banner'

  const tablet = useMedia(media.tablet)
  const [isTablet, setIsTablet] = useState(false)

  useEffect(() => {
    setIsTablet(tablet)
  }, [tablet])

  return (
    <section id="about-us-hero" className={clsx(cx.wrapper)}>
      <ModalVideo childFunc={modalVideoFunc} url={`${video.url}`} />
      <ElementImageFile
        className={clsx(cx.background)}
        src={background}
        alt={`${altTag} Background`}
        lazy={true}
      />
      <div className={clsx(cx.video)}>
        <div className={clsx(cx.videoPlaceholder, imageClassName)}>
          <ElementImageFile src={image} alt={altTag} lazy={true} />

          <div className={cx.videoTitle}>
            <strong>{video.title}</strong>
            {video.subtitle}
          </div>

          <button
            className={cx.play}
            onClick={() => {
              modalVideoFunc.current.openModal()
            }}
          >
            <span className="sr-only">Play Button</span>
            <SvgPlayAlt />
          </button>
        </div>

        <div className={clsx(cx.heading)}>
          <h2 className="heading-5">{parse(title)}</h2>
          <p className="default-body large">{parse(description)}</p>
        </div>
      </div>

      <div className={clsx(cx.features)}>
        {features.map((item, index) => {
          return (
            <div key={index} className={clsx(cx.item, index === 0 && cx.first)}>
              <h3
                className={clsx(
                  !isTablet && 'heading-5',
                  isTablet && 'heading-3'
                )}
              >
                {parse(item.title)}
              </h3>
              <p className="subtitle-5">{parse(item.description)}</p>
            </div>
          )
        })}
      </div>
    </section>
  )
}

AboutUsStory.propTypes = {
  background: PropTypes.object,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  description: PropTypes.string,
  descriptionClassName: PropTypes.string,
  image: PropTypes.object,
  imageClassName: PropTypes.string,
  video: PropTypes.object,
  features: PropTypes.array,
}

export default AboutUsStory
