// extracted by mini-css-extract-plugin
export var background = "AboutUsStory-module--background--ca977";
export var features = "AboutUsStory-module--features--f3567";
export var first = "AboutUsStory-module--first--53bc3";
export var heading = "AboutUsStory-module--heading--357e9";
export var item = "AboutUsStory-module--item--d333f";
export var play = "AboutUsStory-module--play--6189b";
export var video = "AboutUsStory-module--video--10ef4";
export var videoPlaceholder = "AboutUsStory-module--video-placeholder--c25eb";
export var videoTitle = "AboutUsStory-module--video-title--4caa6";
export var wrapper = "AboutUsStory-module--wrapper--d5249";