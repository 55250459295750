import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { removeElementTags } from '@utils/removeElementTags'
import parse from 'html-react-parser'

import ElementImageFile from '@components/global/element/ImageFile'

import * as cx from './AboutUsMission.module.scss'

import SvgBrowserTabs from '@svg/browserTabs.inline'
import SvgConversation from '@svg/conversation.inline'
import SvgTrophy from '@svg/trophy.inline'

const AboutUsMission = ({
  breadcrumbs,
  background,
  title,
  titleClassName = 'heading-5',
  items,
  images,
}) => {
  return (
    <section id="about-us-mission" className={clsx(cx.wrapper)}>
      <div className={clsx(cx.content)}>
        <div className={clsx(cx.textContent)}>
          <h2 className={clsx(titleClassName)}>{parse(title)}</h2>
          <div className={clsx(cx.items)}>
            {items.map((item, index) => {
              return (
                <div key={index} className={clsx(cx.item)}>
                  <div className={clsx(cx.icon)}>{item.icon}</div>
                  <div className={clsx(cx.details)}>
                    <h4 className="subtitle-10">{parse(item.title)}</h4>
                    <p className="default-body large">
                      {parse(item.description)}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className={clsx(cx.imageContent)}>
          <div className={clsx(cx.images)}>
            {images.map((image, index) => {
              return (
                <ElementImageFile
                  key={index}
                  src={image}
                  width={223}
                  height={218}
                  alt={removeElementTags(title)}
                  lazy={true}
                />
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

AboutUsMission.propTypes = {
  background: PropTypes.object,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  items: PropTypes.array,
  images: PropTypes.array,
}

export default AboutUsMission
