import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { removeElementTags } from '@utils/removeElementTags'
import parse from 'html-react-parser'

import ElementImageFile from '@components/global/element/ImageFile'

import * as cx from './AboutUsPartner.module.scss'

import SvgLogo from '@svg/logo.inline'

const AboutUsPartner = ({
  background,
  image,
  signature,
  title,
  titleClassName = 'heading-5',
  description,
  descriptionClassName = 'subtitle-5',
  name,
  position,
}) => {
  console.log(background)
  return (
    <section id="about-us-partner">
      <div className={clsx(cx.wrapperContent)}>
        <div className={clsx(cx.content)}>
          <div className={clsx(cx.description)}>
            <h2 className={clsx(titleClassName)}>{parse(title)}</h2>
            <p className={clsx(descriptionClassName)}>{parse(description)}</p>
            <div className={clsx(cx.signature, descriptionClassName)}>
              Sincerely,
              <ElementImageFile
                src={signature}
                alt="Jon Krabbe - Co-Founder & Managing Partner Agent Image"
                lazy={false}
              />
              <span className="text-primary-0">{parse(name)}</span>
              <span className="text-neutral-500">{parse(position)}</span>
            </div>
          </div>
          <div className={clsx(cx.image)}>
            <ElementImageFile
              src={image}
              alt={removeElementTags(title)}
              lazy={false}
            />
          </div>
        </div>
      </div>
      <div className={clsx(cx.agentimage)}>
        <ElementImageFile
          src={background}
          alt="The Best Websites In Real Estate"
          lazy={false}
          className="image-background"
        />
        <SvgLogo />
        <p className="subtitle-7">The Best Websites In Real Estate</p>
      </div>
    </section>
  )
}

AboutUsPartner.propTypes = {
  background: PropTypes.string,
  image: PropTypes.object,
  signature: PropTypes.object,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  description: PropTypes.string,
  descriptionClassName: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.string,
}

export default AboutUsPartner
