// extracted by mini-css-extract-plugin
export var access = "AboutUsCta-module--access--88a2b";
export var cta = "AboutUsCta-module--cta--896ca";
export var featuresIn = "AboutUsCta-module--features-in--f2cd9";
export var heading = "AboutUsCta-module--heading--49a51";
export var image = "AboutUsCta-module--image--80501";
export var item = "AboutUsCta-module--item--3dd79";
export var items = "AboutUsCta-module--items--16f10";
export var link = "AboutUsCta-module--link--79cc5";
export var logos = "AboutUsCta-module--logos--a0fea";
export var subheading = "AboutUsCta-module--subheading--b1ed6";
export var svg = "AboutUsCta-module--svg--adf56";
export var wrapper = "AboutUsCta-module--wrapper--739c2";