import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import SvgBrowserTabs from '@svg/browserTabs.inline'
import SvgConversation from '@svg/conversation.inline'
import SvgTrophy from '@svg/trophy.inline'

export const useAboutUsMetadata = () => {
  const query = useStaticQuery(graphql`
    query AboutUsMetaData {
      HeroBackground: file(
        relativePath: { eq: "about-us/hero/background.jpg" }
      ) {
        id
        ...AboutUsImageQuality
      }
      Hero: file(relativePath: { eq: "about-us/hero/image.png" }) {
        id
        ...AboutUsImageQuality
      }
      StoryBackground: file(
        relativePath: { eq: "about-us/story/background.jpg" }
      ) {
        id
        ...AboutUsImageQuality
      }
      StoryVidePlaceholder: file(
        relativePath: { eq: "about-us/story/image.jpg" }
      ) {
        id
        ...AboutUsImageQuality
      }
      Item1: file(relativePath: { eq: "about-us/mission/item-1.jpg" }) {
        id
        ...AboutUsImageQuality
      }
      Item2: file(relativePath: { eq: "about-us/mission/item-2.jpg" }) {
        id
        ...AboutUsImageQuality
      }
      Item3: file(relativePath: { eq: "about-us/mission/item-3.jpg" }) {
        id
        ...AboutUsImageQuality
      }
      Item4: file(relativePath: { eq: "about-us/mission/item-4.jpg" }) {
        id
        ...AboutUsImageQuality
      }
      Item5: file(relativePath: { eq: "about-us/mission/item-5.jpg" }) {
        id
        ...AboutUsImageQuality
      }
      Item6: file(relativePath: { eq: "about-us/mission/item-6.jpg" }) {
        id
        ...AboutUsImageQuality
      }
      OurSolutions: file(
        relativePath: { eq: "about-us/our-solutions/image.png" }
      ) {
        id
        ...AboutUsImageQuality
      }
      RealEstateWebsite: file(
        relativePath: { eq: "about-us/cta/website.png" }
      ) {
        id
        ...AboutUsImageQuality
      }
      RealEstateDigitalMarketing: file(
        relativePath: { eq: "about-us/cta/digital-marketing.png" }
      ) {
        id
        ...AboutUsImageQuality
      }
      RealEstateAccess: file(
        relativePath: { eq: "about-us/cta/access-long.png" }
      ) {
        id
        ...AboutUsImageQuality
      }
      LogoRealTrends: file(
        relativePath: { eq: "about-us/logo/real-trends.png" }
      ) {
        id
        ...AboutUsImageQuality
      }
      LogoInman: file(relativePath: { eq: "about-us/logo/inman.png" }) {
        id
        ...AboutUsImageQuality
      }
      LogoHousingWire: file(
        relativePath: { eq: "about-us/logo/housing-wire.png" }
      ) {
        id
        ...AboutUsImageQuality
      }
      LogoTomFerry: file(relativePath: { eq: "about-us/logo/tom-ferry.png" }) {
        id
        ...AboutUsImageQuality
      }
      LogoLuxuryPortfolio: file(
        relativePath: { eq: "about-us/logo/luxury.png" }
      ) {
        id
        ...AboutUsImageQuality
      }
      LogoBusinessInsider: file(
        relativePath: { eq: "about-us/logo/business-insider.png" }
      ) {
        id
        ...AboutUsImageQuality
      }
      LogoForbes: file(relativePath: { eq: "about-us/logo/forbes.png" }) {
        id
        ...AboutUsImageQuality
      }
      LogoTheRealDeal: file(
        relativePath: { eq: "about-us/logo/real-deal.png" }
      ) {
        id
        ...AboutUsImageQuality
      }
      FounderBackground: file(
        relativePath: { eq: "about-us/partner/background.jpg" }
      ) {
        id
        ...AboutUsImageQuality
      }
      JonKrabbe: file(relativePath: { eq: "about-us/partner/jon-krabbe.png" }) {
        id
        ...AboutUsImageQuality
      }
      Signature: file(relativePath: { eq: "about-us/partner/signature.png" }) {
        id
        ...AboutUsImageQuality
      }
    }

    fragment AboutUsImageQuality on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)

  return query
}

export const heroData = {
  title: 'The Leader In Real Estate Website Design <br />& Digital Marketing',
  subtitle: '23 years of expertise',
  description:
    'We believe in the transformative power of technology. At the same time, we believe that excellent customer service never goes out of style. Our dedication to both innovation and client satisfaction has made us a leading digital transformation partner for agents and brokers.',
}

export const storyData = {
  title: 'Our Story',
  description:
    'Agent Image has grown from a team of three in a one-room office on Venice Beach, California to a global organization with over 300 employees on two continents. While a two-decade growth trajectory has changed the company in many ways, one value remains constant – this business is home and clients are treated like family.',
  founded: {
    title: '1999',
    subtitle: 'Founded on Venice Beach',
    url: 'https://www.youtube.com/embed/_yRQf-0kyGc',
  },
  features: [
    {
      title: '25,000+',
      description: 'Successfully launched <br />real estate websites',
    },
    {
      title: '23 Years',
      description:
        'Impeccable track record for best-in-class <br />websites & digital campaigns.',
    },
    {
      title: '6,800+',
      description: 'Five-star reviews from <br />top-producing agents',
    },
  ],
}

export const missionData = {
  title: 'What Drives Us',
  items: [
    {
      icon: <SvgBrowserTabs />,
      title: 'Passion For Great Design',
      description:
        'We create beautifully designed, elegant solutions that elevate you and your business.',
    },
    {
      icon: <SvgConversation />,
      title: 'Unparalleled Service',
      description:
        'Expect to receive the same VIP treatment you lavish on your own clients.',
    },
    {
      icon: <SvgTrophy />,
      title: 'Obsessed With Your Success',
      description:
        'We work tirelessly to boost your business because our success depends on yours.',
    },
  ],
}

export const ourSolutionsData = {
  title: 'Our Solutions. <br />Your Success.',
  description:
    'We don’t just build websites — we help real estate professionals build successful businesses through our end-to-end services. That’s why billion-dollar-selling celebrity agents choose us to revamp their brand, bolster their online presence, and expand their reach. When it comes to results-oriented web design and digital marketing campaigns, Agent Image is second to none.',
  items: [
    'Website Design',
    'Website Support',
    'Search Engine Optimization',
    'Social Media Marketing',
    'IDX Integration',
    'Logo Design & Branding',
    'Property Websites',
    'Lead Generation',
    'Broker & Developer Solutions',
    'CRM Integration',
    'Digital Listing Presentations',
    'Open House Presentations',
  ],
}

export const ctaData = {
  title: 'Solutions For Every Budget.',
  description:
    'From entry-level template websites to fully bespoke creations, Agent Image can do it all. Plus, we have a comprehensive suite of digital marketing services including SEO, social media management, and pay-per-click advertising. No matter where you are in your career, you can mix and match our services to reach your business goals.',
  items: [
    {
      title: 'Real Estate <br/>Websites',
      description:
        'Impeccably designed websites equipped with smart lead generation tools',
      label: 'Learn More',
      url: '/real-estate-websites/',
    },
    {
      title: 'Real Estate <br/>Digital Marketing',
      description:
        'Have all your online touchpoints covered with flexible digital marketing services',
      label: 'Learn More',
      url: '/real-estate-digital-marketing/',
    },
    {
      title: 'Access',
      description: 'The antidote to boring real estate presentations.',
      label: 'Learn More',
      url: 'https://www.access.com/',
    },
  ],
  features: {
    title: 'As Featured in',
  },
}

export const founderStatement = {
  title: "Let's Do Great Things Together.",
  description:
    'As a founding partner of Agent Image, I am personally committed to ensuring every customer experience is one I am proud of. We have assembled a team dedicated to shared values, a vigorous work ethic, and outstanding client service to accomplish this goal. Collectively, we like to think our effort delivers on our most valued result – customers who think we rock.',
  name: 'Jon Krabbe',
  position: 'Co-Founder & Managing Partner <br />Agent Image',
}
