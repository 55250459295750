import React from 'react'

const SvgLogoAccessAlt = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 460 64"
      style={{ enableBackground: 'new 0 0 460 64' }}
      fill="none"
      className="fill-current"
    >
      <path d="M92.4676 62.5581H90.5262C85.2064 62.5483 80.0852 60.525 76.1816 56.8908C70.1169 51.4536 63.4264 46.7664 56.253 42.9294L53.7935 41.5905L56.4578 40.7667C57.0219 40.613 70.0855 36.6964 78.3339 39.2726L78.7436 39.3756L78.9484 39.7368C83.5329 46.511 87.7237 53.5463 91.4999 60.8073L92.4676 62.5581Z" />
      <path d="M0 63.9952L2.61241 58.6369C13.6781 36.0216 35.7086 8.2033 40.166 3.61815C44.5211 -0.914739 46.7743 -1.12071 50.5148 2.5883C58.6165 10.4705 72.2884 28.7066 72.903 29.4798L74.3888 31.438L71.9292 31.3858C37.8089 30.5619 4.76471 59.822 4.40549 60.0802L0 63.9952Z" />
      <path d="M426.045 18.5127C422.407 14.6484 416.515 11.7648 407.701 11.7648C397.712 11.7648 392.947 14.7529 392.947 18.6172C392.947 23.0994 400.274 24.3352 408.829 25.0561C423.738 26.3949 437.567 29.177 437.567 41.4384C437.567 52.8744 423.478 57.8208 407.597 57.8208C393.048 57.8208 382.545 53.6998 376.499 45.1997L386.028 40.0474C390.896 46.7445 399.092 49.1654 407.802 49.1654C416.255 49.1654 424.197 47.0534 424.197 41.4384C424.197 36.5966 417.177 34.5861 407.7 33.8652C393.15 32.6355 379.676 29.8472 379.676 18.3067C379.676 7.74533 394.174 3.41842 407.29 3.31543C415.231 3.31543 423.377 4.50053 429.371 8.05429C431.723 9.43782 433.764 11.2975 435.365 13.5156L426.045 18.5127Z" />
      <path d="M151.443 42.88L151.291 42.777L151.034 42.983C145.764 46.382 139.619 48.155 133.358 48.083C115.735 48.083 109.33 39.3216 109.228 31.135C109.221 29.1477 109.613 27.1794 110.378 25.3473C111.144 23.5152 112.269 21.8568 113.685 20.4707C116.707 17.4318 122.551 13.7735 133.358 13.7735C139.573 13.7383 145.663 15.5291 150.879 18.9259L151.185 19.1319L151.39 18.9782C152.432 15.5054 153.913 12.1813 155.795 9.08695C148.883 5.42011 141.17 3.54313 133.355 3.62566C116.294 3.62566 106.971 9.44663 102.155 14.29C99.739 16.6351 97.8559 19.4774 96.6341 22.6223C95.4124 25.7671 94.8809 29.1403 95.076 32.511C95.2711 35.8816 96.1882 39.17 97.7646 42.1511C99.3409 45.1322 101.539 47.7356 104.209 49.7831C110.972 55.2444 121.013 58.1279 133.309 58.1279C141.178 58.294 148.974 56.5815 156.057 53.1309C154.086 49.928 152.536 46.4819 151.446 42.8784" />
      <path d="M203.238 58.0756C190.942 58.0756 180.9 55.1905 174.138 49.7307C171.314 47.5261 169.022 44.708 167.434 41.4874C165.846 38.2667 165.002 34.7266 164.966 31.1319C164.971 27.98 165.604 24.8611 166.828 21.9591C168.051 19.0571 169.839 16.4307 172.088 14.2346C176.903 9.39277 186.177 3.57027 203.288 3.57027C212.706 3.38468 221.948 6.15778 229.724 11.5032L230.288 11.8644L220.505 18.6645L220.248 18.4586C215.203 15.1882 209.289 13.5347 203.288 13.7166C192.478 13.7166 186.639 17.3749 183.615 20.4137C182.199 21.7999 181.074 23.4583 180.308 25.2904C179.543 27.1225 179.151 29.0908 179.158 31.0781C179.26 39.2693 185.613 48.0261 203.288 48.0261C209.549 48.0981 215.694 46.3251 220.964 42.926L221.221 42.7201L231.21 49.9844L230.698 50.3456C223.474 55.4457 214.048 58.0726 203.238 58.0726" />
      <path d="M356.062 18.5127C352.424 14.6484 346.532 11.7648 337.718 11.7648C327.727 11.7648 322.964 14.7529 322.964 18.6172C322.964 23.0994 330.291 24.3352 338.846 25.0561C353.755 26.3949 367.585 29.177 367.585 41.4384C367.585 52.8744 353.495 57.8208 337.614 57.8208C323.065 57.8208 312.562 53.6998 306.516 45.1997L316.046 40.0474C320.913 46.7445 329.109 49.1654 337.767 49.1654C346.22 49.1654 354.162 47.0534 354.162 41.4384C354.162 36.5966 347.142 34.5861 337.665 33.8652C323.115 32.6355 309.641 29.8472 309.641 18.3067C309.641 7.74533 324.088 3.41842 337.255 3.31543C345.196 3.31543 353.342 4.50053 359.336 8.05429C361.684 9.42496 363.711 11.2875 365.279 13.5156L356.062 18.5127Z" />
      <path d="M258.261 26.3424V14.4944H289C291.473 14.4944 293.844 13.5066 295.593 11.7482C297.342 9.98979 298.324 7.60491 298.324 5.11816H244.89V56.4802H298.324C298.321 53.9537 297.321 51.5316 295.545 49.745C293.768 47.9585 291.359 46.9534 288.847 46.9502H258.261V26.3424Z" />
      <path d="M258.26 34.3261H274.808C279.777 34.3261 284.695 31.6992 284.695 26.7021H266.668L258.26 34.3261Z" />
      <path d="M448.719 10.3104V4.57085H450.851V3.46875H445.348V4.57085H447.488V10.3104H448.719ZM460 10.3104V3.46875H458.115L455.932 8.69487H455.912L453.725 3.46875H451.891V10.3104H453.08V4.90132H453.1L455.338 10.3104H456.455L458.748 4.79834H458.769V10.3104H460Z" />
    </svg>
  )
}

export default SvgLogoAccessAlt
