import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { removeElementTags } from '@utils/removeElementTags'
import parse from 'html-react-parser'

import ElementImageFile from '@components/global/element/ImageFile'

import * as cx from './AboutUsOurSolutions.module.scss'

import SvgPlayAlt from '@svg/playAlt.inline'

const AboutUsOurSolutions = ({
  background,
  title,
  titleClassName = 'heading-5',
  description,
  descriptionClassName = 'default-body large',
  items,
  image,
}) => {
  return (
    <section id="about-us-our-story" className={clsx(cx.wrapper)}>
      <div className={clsx(cx.content)}>
        <div className={clsx(cx.heading)}>
          <div className={clsx(cx.description)}>
            <h2 className={clsx(titleClassName)}>{parse(title)}</h2>
            <p className={clsx(descriptionClassName)}>{parse(description)}</p>
          </div>
          <div className={clsx(cx.image)}>
            <ElementImageFile
              src={image}
              alt={removeElementTags(title)}
              lazy={true}
            />
          </div>
        </div>
        <div className={clsx(cx.lists)}>
          <ul>
            {items.map((item, index) => {
              return (
                <li key={index} className="subtitle-6">
                  {item}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </section>
  )
}

AboutUsOurSolutions.propTypes = {
  background: PropTypes.object,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  description: PropTypes.string,
  descriptionClassName: PropTypes.string,
  items: PropTypes.array,
  image: PropTypes.object,
}

export default AboutUsOurSolutions
