import React from 'react'
import clsx from 'clsx'

const SvgBrowserTabs = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 42"
      style={{ enableBackground: 'new 0 0 48 42' }}
      className={clsx('fill-current', className)}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.68238 0.394543H47.2258C47.6427 0.394543 48 0.751863 48 1.10918V35.7692C48 36.1861 47.6427 36.5434 47.2258 36.5434H41.7469V40.8313C41.7469 41.2481 41.3896 41.6055 40.9727 41.6055H0.774194C0.35732 41.6055 0 41.2481 0 40.8313V6.23077C0 5.8139 0.35732 5.45658 0.774194 5.45658H6.90819V1.16874C6.90819 0.751863 7.26551 0.394543 7.68238 0.394543ZM40.1985 10.2208V6.88586H1.48883V10.2208H40.1985ZM1.48883 11.7097V39.9975H40.1985V11.7097H1.48883ZM41.6873 34.995H46.5112V1.88338H8.39702V5.39702H40.9132C41.33 5.39702 41.6873 5.75434 41.6873 6.17122V34.995ZM6.01489 7.83871H4.52605V9.32754H6.01489V7.83871ZM9.52854 7.83871H11.0174V9.32754H9.52854V7.83871ZM16.0198 7.83871H14.531V9.32754H16.0198V7.83871Z"
        fill="url(#paint0_linear_805_15704)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_805_15704"
          x1="-3.95506"
          y1="41.6055"
          x2="41.2126"
          y2="55.9385"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8DF6F8" />
          <stop offset="1" stopColor="#6876D5" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgBrowserTabs
